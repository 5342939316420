import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
// Assets
import Logo from "../../assets/logo.png";
import Resume from "../../assets/CarlosGuzmanFrontEndResumeUpdated.pdf";
import Avatar from "../../assets/selfPortrait2021.jpg";
import SecndChance from "../../assets/websiteScreenshots/secndchance.us_ (2).png";
import Three14 from "../../assets/websiteScreenshots/three14.llc_ (1).png";
import Alpha from "../../assets/websiteScreenshots/localhost_3000_f_realmadrid2.png";
import Ecommerce from "../../assets/websiteScreenshots/ecommerceWebsite.png";
import OldPortfolio from "../../assets/websiteScreenshots/localhost_3000_resume.png";
import OutFurDelivery from "../../assets/websiteScreenshots/cvrlxsguzmanhosting.com_outfurdelivery_ (1).png";
// Icons
import {
  FiGithub,
  FiInstagram,
  FiTwitter,
  FiChevronsRight,
  FiExternalLink,
  FiMenu,
} from "react-icons/fi";
import {
  IoLocationOutline,
  IoMailOutline,
  IoPhonePortraitOutline,
  IoRocketOutline,
} from "react-icons/io5";
// Styles
import "./styles.scss";
// Firebase
import { analytics, logEvent } from "../../firebase/config";
const HomePage = () => {
  const [showNav, setShowNav] = useState(false);
  const concernedElement = document.querySelector(".mobileNav");
  document.addEventListener("mousedown", (event) => {
    if (!concernedElement.contains(event.target)) {
      setShowNav(!setShowNav);
    }
  });

  useEffect(() => {
    logEvent(analytics, "visited_site", {});
  });

  return (
    <div className="home-page">
      <div className="navbar fontCode">
        <HashLink to={`/#home`} className="logo">
          <img src={Logo} alt="logo" />
        </HashLink>
        <div className="links">
          <HashLink to={`/#about`}>About</HashLink>
          <HashLink to={`/#experience`}>Experience</HashLink>
          <HashLink to={`/#latest1`}>Latest Projects</HashLink>
          <HashLink to={`/#contact`}>Contact</HashLink>
          <a
            href={Resume}
            download="CarlosGuzmanResume"
            className="resumeButton"
            onClick={() => {
              logEvent(analytics, "viewed_resume", {});
            }}
          >
            Resume
          </a>
        </div>
      </div>

      <div className="collapseNavbar fontCode">
        <HashLink to={`/#home`} className="logo">
          <img src={Logo} alt="logo" />
        </HashLink>
        <button className="bars" onClick={() => setShowNav(!showNav)}>
          <FiMenu />
        </button>
      </div>

      <div className={showNav ? "active mobileNav" : "mobileNav"}>
        <div className="links">
          <HashLink to={`/#about`} onClick={() => setShowNav(!showNav)}>
            About
          </HashLink>
          <HashLink to={`/#experience`} onClick={() => setShowNav(!showNav)}>
            Experience
          </HashLink>
          <HashLink to={`/#latest1`} onClick={() => setShowNav(!showNav)}>
            Latest Projects
          </HashLink>
          <HashLink to={`/#contact`} onClick={() => setShowNav(!showNav)}>
            Contact
          </HashLink>
          <a
            href={Resume}
            download="CarlosGuzmanResume"
            className="resumeButton"
          >
            Resume
          </a>
        </div>
        <div className="socials">
          <a href="https://github.com/cvrlxsguzman">
            <FiGithub />
          </a>
          <a href="https://twitter.com/cvrlxsguzman">
            <FiTwitter />
          </a>
          <a href="https://www.instagram.com/cvrlxsguzman/">
            <FiInstagram />
          </a>
        </div>
      </div>

      <div className="sidebar" id="sidebar">
        <a href="https://github.com/cvrlxsguzman">
          <FiGithub />
        </a>
        <a href="https://twitter.com/cvrlxsguzman">
          <FiTwitter />
        </a>
        <a href="https://www.instagram.com/cvrlxsguzman/">
          <FiInstagram />
        </a>
      </div>

      <div className="full container fontRoboto" id="home">
        <p className="lightBlue">Hello, I am</p>
        <h1>Carlos Guzman.</h1>
        <h1 className="gray">I create websites.</h1>
        <p className="gray">
          I am a web & software developer that is primarily focused on building
          and designing front-end websites.
        </p>
      </div>

      <div className="container">
        <div className="full fontNoto" id="about">
          <h1>About Me</h1>
          <div className="content">
            <p className="fontRoboto">
              Hello, my name is Carlos Guzman. I am a developer that enjoys
              creating software. I was first introduced to HTML and CSS when I
              was 11 in 2007-08 with the use of MySpace. Back then, MySpace used
              to allow you to apply custom themes that you could copy from the
              internet. I tinkered with them a lot, and gained a passion for
              creating.
              <br />
              <br />I graduated from Lakeshore Technical College in 2021 with an
              Associates in Science: IT-Web & Software developing degree. I also
              obtained a certificate in Web Development. While in school, I had
              the opportunity to tutor software development. I was able to use
              my skills to teach others and learn more about my own
              capabilities.
              <br />
              <br />
              Recently, my focus has been on expanding my knowledge in front-end
              design and development predominantly with ReactJS and React
              Native.
            </p>
            <div className="img">
              <img src={Avatar} alt="Carlos Guzman/Me" />
              <div className="overlay"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="full container fontRoboto" id="experience">
        <div className="workExperience">
          <h1>My Experience</h1>
          <div className="job">
            <h1>Three14</h1>
            <div className="content">
              <div className="title">
                <h1>Software Developer</h1>{" "}
                <h1 className="lightBlue"> @ Three14 LLC </h1>
              </div>
              <p>February 2022 - Present</p>
              <p>
                <FiChevronsRight className="lightBlue" /> I started a software
                development company.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> I created a website
                for a client to their specifications and wireframes.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> I reached out to a few
                local businesses to find potential clients.
              </p>
            </div>
          </div>
          <div className="job">
            <h1>Lakeshore</h1>
            <div className="content">
              <div className="title">
                <h1>Tutor</h1>{" "}
                <h1 className="lightBlue"> @ Lakeshore Technical College </h1>
              </div>
              <p>January 2021 - May 2021</p>
              <p>
                <FiChevronsRight className="lightBlue" /> I tutored HTML, CSS,
                and JavaScript.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> I helped first year
                students understand development principles and standardization.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Communicated with
                multiple students and teachers on a regular basis.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="latest1"></div>
      <div className="container">
        <div className="fontRoboto" id="latest">
          <h1>Latest Projects</h1>
          <div className="project">
            <a
              href="http://cvrlxsguzmanhosting.com/outfurdelivery/"
              target="_blank"
            >
              <img src={OutFurDelivery} alt="Three14 LLC Website" />
            </a>
            <div className="details">
              <h1>Out Fur Delivery</h1>
              <p>
                For this site, I worked with a marketing and two graphic design
                students while attending school. This was our semester project.
                I was the only dev for this project. I created the front end,
                back end and database.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> PHP
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> MySQL
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Bootstrap
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> HTML & CSS
              </p>
              <a
                href="http://cvrlxsguzmanhosting.com/outfurdelivery/"
                target="_blank"
                className="link"
              >
                <FiExternalLink />
              </a>
            </div>
          </div>
          <div className="project">
            <div className="details">
              <h1>SecndChance</h1>
              <p>
                A website I created for a client using their wireframes. It is
                minimalistic and very easy to use.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> ReactJS
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> SCSS
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Firebase Database
              </p>
              <a
                href="https://secndchance.us/"
                target="_blank"
                className="link"
              >
                <FiExternalLink />
              </a>
            </div>
            <a href="https://secndchance.us/" target="_blank">
              <img src={SecndChance} alt="SecndChance Website" />
            </a>
          </div>
          <div className="project">
            <a href="https://three14.llc/" target="_blank">
              <img src={Three14} alt="Three14 LLC Website" />
            </a>
            <div className="details">
              <h1>Three14</h1>
              <p>
                This is the main website for my company. Once finished, it will
                show information on what we offer, our contact information and
                more.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> ReactJS
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> SCSS
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Firebase Hosting
              </p>
              <a href="https://three14.llc/" target="_blank" className="link">
                <FiExternalLink />
              </a>
              <p>
                <i>
                  <small>*Not Complete</small>
                </i>
              </p>
            </div>
          </div>
          <div className="project">
            <div className="details">
              <h1>Alpha Social</h1>
              <p>
                This is a social media mock website that I am working on. It
                will have the ability to create profiles, make posts, follow
                people and more.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> ReactJS
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> SCSS
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Firebase Database
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Authentication
              </p>
              <a
                href="https://imgraphy-9292e.firebaseapp.com/"
                target="_blank"
                className="link"
              >
                <FiExternalLink />
              </a>
              <p>
                <i>
                  <small>*Not Complete</small>
                </i>
              </p>
            </div>
            <a href="https://imgraphy-9292e.firebaseapp.com/" target="_blank">
              <img src={Alpha} alt="Alpha Social Website" />
            </a>
          </div>
          <div className="project">
            <a
              href="https://ecommerce-store-template.firebaseapp.com/"
              target="_blank"
            >
              <img src={Ecommerce} alt="Ecommerce Website" />
            </a>
            <div className="details">
              <h1>Ecommerce</h1>
              <p>
                On this site, I created a mock website with authentication. It
                shows a basic layout for potential ecommerce websites.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> ReactJS
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> SCSS
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Redux
              </p>
              <a
                href="https://ecommerce-store-template.firebaseapp.com/"
                target="_blank"
                className="link"
              >
                <FiExternalLink />
              </a>
              <p>
                <i>
                  <small>*Not Complete</small>
                </i>
              </p>
            </div>
          </div>
          <div className="project">
            <div className="details">
              <h1>Previous Portfolio</h1>
              <p>
                This is my previous portfolio. I am proud of the look of it. It
                is uses a non complex layout with pages instead of a single page
                like my current one.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> ReactJS
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> SCSS
              </p>
              <a
                href="https://old-portfolio-2d938.firebaseapp.com/"
                target="_blank"
                className="link"
              >
                <FiExternalLink />
              </a>
            </div>
            <a
              href="https://old-portfolio-2d938.firebaseapp.com/"
              target="_blank"
            >
              <img src={OldPortfolio} alt="Carlos Guzman's Old Portfolio" />
            </a>
          </div>
        </div>
      </div>

      <div className="full container fontRoboto" id="contact">
        <h1>Get In Touch</h1>
        <p>
          Feel free to contact me for any reason. My inbox is always open. I
          will try my best to reply promptly. Stay golden!
        </p>
        <div className="contactInfo">
          <IoMailOutline className="icon" />
          <div className="details">
            <h2>Email</h2>
            <p>cvrlxsguzman@gmail.com</p>
          </div>
        </div>
        <div className="contactInfo">
          <IoLocationOutline className="icon" />
          <div className="details">
            <h2>Location</h2>
            <p>Sheboygan, Wisconsin, United States</p>
          </div>
        </div>
        <a href="mailto:cvrlxsguzman@gmail.com" className="button lightGray">
          Contact Me
        </a>
      </div>

      <div className="footer fontCode" id="footer">
        <p className="fontCode">
          <small>By Carlos Guzman 2022</small>
        </p>
      </div>
    </div>
  );
};

export default HomePage;

import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { db } from "../firebase/config";
import { collection, addDoc, onSnapshot } from "firebase/firestore";

const localizer = momentLocalizer(moment);

function WorkCalendar() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "workEvents"), (snapshot) => {
      const eventsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(eventsData);
    });

    return () => unsubscribe();
  }, []);

  const handleSelectSlot = async ({ start, end }) => {
    const title = prompt("Enter Event Title");
    if (title) {
      await addDoc(collection(db, "workEvents"), {
        title,
        start,
        end,
      });
    }
  };

  return (
    <div>
      <h1>Work Calendar</h1>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600 }}
        selectable
        onSelectSlot={handleSelectSlot}
      />
    </div>
  );
}

export default WorkCalendar;
